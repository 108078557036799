import { type PAGE_TYPE } from '@/constants/pageTypes'
import { useLocale } from '@/hooks/useLocale'

import { type GTM_SRP_VIEW_TYPE, transformPage } from '..'

export type UsePageProps = {
  /**
   * Additional data to add to the page data
   */
  additionalData?: {
    /**
     * SRP view type
     */
    srpViewType?: GTM_SRP_VIEW_TYPE
  }
  /**
   * Allowed GTM pages / page types
   */
  pageType: PAGE_TYPE
}

export const usePage = ({ additionalData, pageType }: UsePageProps) => {
  const { apiLocale } = useLocale()

  return transformPage({ additionalData, apiLocale, pageType })
}
