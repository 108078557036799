import { ThemeProps } from '@kijiji/theme'
import styled, { css } from 'styled-components'

import {
  KEYBOARD_ONLY_OUTLINE,
  TRANSITION_STYLE,
} from '@/ui/constants/commonStyles'
import { styleFromTypography } from '@/ui/helpers/styleFromTypography'
import { RequiredPickMap } from '@/ui/typings/helpers'

import { ButtonProps } from './Button'
import {
  buttonColorVariation,
  buttonScale,
  styleFromButtonColorVariation,
} from './utils'

type ButtonBaseProps = RequiredPickMap<
  ButtonProps,
  'isFullWidth' | 'isLoading' | 'size' | 'variant'
> & {
  $as?: string
}

export type SharedButtonStyleProps = RequiredPickMap<
  ButtonProps,
  'isFullWidth' | 'size' | 'variant'
> & {
  theme: ThemeProps
}

export const sharedButtonStyle = ({
  theme,
  $isFullWidth = false,
  $size = 'medium',
  $variant = 'primary',
}: SharedButtonStyleProps) => css`
  ${KEYBOARD_ONLY_OUTLINE}
  ${TRANSITION_STYLE}
  ${styleFromTypography(theme.typography.body[$size])}
  ${styleFromButtonColorVariation(
    buttonColorVariation(theme)[$variant].default
  )}
  align-items: center;
  border-radius: ${theme.borderRadius.small};
  cursor: pointer;
  display: flex;
  font-family: inherit;
  font-weight: ${theme.fontWeight.medium};
  justify-content: center;
  margin: 0;
  min-height: ${buttonScale[$size]?.height || buttonScale.medium.height};
  padding: ${buttonScale[$size]?.padding || buttonScale.medium.padding};
  width: ${$isFullWidth ? '100%' : 'fit-content'};

  &:hover {
    ${styleFromButtonColorVariation(
      buttonColorVariation(theme)[$variant].hover
    )}
    text-decoration: none;
  }
  &:active,
  &:focus {
    ${styleFromButtonColorVariation(
      buttonColorVariation(theme)[$variant].focus
    )}
  }
  &:disabled {
    ${styleFromButtonColorVariation(
      buttonColorVariation(theme)[$variant].disabled
    )}
    cursor: not-allowed;
  }
`

export const ButtonBase = styled.button<ButtonBaseProps>`
  ${({ theme, $isFullWidth, $size, $variant, $isLoading, $as }) => css`
    ${sharedButtonStyle({ theme, $isFullWidth, $size, $variant })}

    ${$as === 'a' ? `text-decoration: none;` : ``}

    ${$isLoading
      ? `
      ${styleFromButtonColorVariation(
        buttonColorVariation(theme)[$variant].loading
      )}
      cursor: ${$isLoading ? 'wait' : 'not-allowed'}`
      : ``}
  `}
`
