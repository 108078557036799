import { z } from "zod";
import config from "../next-seo.config";
import { DefaultSeoProps } from 'next-seo';

const DefaultSeoSchema = z.object({
  titleLong: z.string(),
  title: z.string(),
  description: z.string(),
  image: z.string(),
  titleTemplate: z.string(),
});

type defaultSeo = z.infer<typeof DefaultSeoSchema>;

export const defaultSeo = ({ titleLong, titleTemplate, title, description, image }: defaultSeo): DefaultSeoProps => ({
  ...config,
  title: titleLong,
  titleTemplate,
  openGraph: {
    title,
    description,
  },
  additionalMetaTags: [
    {
      property: 'og:image:secure_url',
      content: image,
    },
  ],
})
