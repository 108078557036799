import { type GetServerSideProps } from 'next'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { useEffect, useState } from 'react'

import { PAGE_TYPE } from '@/constants/pageTypes'
import { SYSTEM_MESSAGES } from '@/constants/systemMessages'
import { useSignin } from '@/hooks/useSignin'
import { useTracking } from '@/lib/ga'
import profile from '@/lib/metrics/profileWrapper'
import { Flex } from '@/ui/atoms/flex'
import { Loading } from '@/ui/atoms/loading'
import { validateRedirectUrl } from '@/utils/url'

export type SigninErrorPageProps = {
  signInRetried: boolean
  callbackUrl: string | null
}

export const SIGNIN_RETRIED = 'signInRetried'

/**
 * This replaces the default signIn error page that next-auth would otherwise use.
 * https://next-auth.js.org/configuration/pages
 *
 * The general goals here are to:
 * 1. record signIn errors for the sake of visibility
 * 2. recover from signIn errors
 *
 * To that end, if useSession tells us that the user is authenticated, we can proceed
 * to the callbackURL.
 *
 * Whereas, if useSession tell us that the user is unauthenticated, then we can reinvoke
 * useSignin to hopefully complete the OAuth flow without errors, while also taking advantage
 * of SSO provided by CIS.
 **/
const SigninErrorPage = ({ callbackUrl, signInRetried }: SigninErrorPageProps) => {
  useTracking({ pageType: PAGE_TYPE.SIGNIN_ERROR })
  const { push } = useRouter()
  const { data: session, status } = useSession()
  const [goToSignIn, setGoToSignIn] = useState(false)

  useSignin({
    callbackUrl: callbackUrl || '/',
    goToSignIn,
    systemMessageParams: {
      [SIGNIN_RETRIED]: true.toString(),
    },
  })

  //TODO: log errors to sentry
  //TODO: log metrics to graphite or prometheus

  useEffect(() => {
    switch (status) {
      case 'loading':
        return
      case 'unauthenticated':
        if (signInRetried) {
          const [callbackUrlWithPath, callbackQueryParamsString] = (callbackUrl ?? '/').split('?')
          const callbackParams = new URLSearchParams(callbackQueryParamsString)
          callbackParams.append('uli', false.toString())
          callbackParams.append('error', 'generic')
          const callbackUrlWithErrorParams = `${callbackUrlWithPath ?? '/'}?${callbackParams}`

          push(callbackUrlWithErrorParams)
        } else {
          setGoToSignIn(true)
        }
        return
      case 'authenticated':
        {
          const [callbackUrlWithPath, callbackQueryParamsString] = (callbackUrl ?? '/').split('?')
          const callbackParams = new URLSearchParams(callbackQueryParamsString)
          callbackParams.append('uli', true.toString())
          const callbackUrlWithSuccessParams = `${callbackUrlWithPath ?? '/'}?${callbackParams}`

          push(callbackUrlWithSuccessParams)
        }
        return
    }
  }, [callbackUrl, push, session, signInRetried, status])

  return (
    <Flex aria-busy justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <Loading size="medium" variant="secondary" />
    </Flex>
  )
}

/**
 * Example callbackUrl:
 * https://fes.dev.kjdev.ca:3100/b-phone-tablet/canada/c132l0?for-sale-by=ownr&siteLocale=en-CA&sort=dateDesc&uli=true
 *
 */
export const getServerSideProps: GetServerSideProps = profile<GetServerSideProps>(
  'auth-signin',
  'getServerSideProps',
  async (context) => {
    const {
      query: { callbackUrl: callbackUrlParam },
    } = context
    /**
     * `callbackUrl` will be the URL to which we should return the user
     * after signin, successful or not.
     **/
    const callbackUrl = Array.isArray(callbackUrlParam) ? callbackUrlParam[0] : callbackUrlParam
    if (!callbackUrl) {
      return {
        props: {
          signInRetried: false,
          callbackUrl: '/',
        },
      }
    }
    const [callbackUrlWithPath, callbackUrlQueryString] = callbackUrl.split('?')
    const callbackQueryParams = new URLSearchParams(callbackUrlQueryString)

    const signInRetried = callbackQueryParams.has(SIGNIN_RETRIED)
    callbackQueryParams.delete(SIGNIN_RETRIED)
    callbackQueryParams.delete(SYSTEM_MESSAGES.uli.key)

    return {
      props: {
        signInRetried,
        callbackUrl: composeSanitizedCallbackUrl(
          validateRedirectUrl(callbackUrlWithPath, process.env.REDIRECT_DOMAIN_WHITELIST ?? ''),
          callbackQueryParams
        ),
      },
    }
  }
)

const composeSanitizedCallbackUrl = (
  callbackUrlWithPath: string,
  callbackQueryParams: URLSearchParams
) => {
  if (
    callbackUrlWithPath.includes(`/auth/signin`) ||
    callbackUrlWithPath.includes(`/consumer/login`)
  ) {
    return `/`
  }

  return callbackQueryParams.toString().length > 0
    ? `${callbackUrlWithPath}?${callbackQueryParams}`
    : callbackUrlWithPath
}

export default SigninErrorPage
