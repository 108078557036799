import styled from 'styled-components'

import { BodyText } from '@/ui/atoms/body-text'
import { Button } from '@/ui/atoms/button'
import { Flex } from '@/ui/atoms/flex'
import { Toggle } from '@/ui/atoms/toggle'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'
import { Modal } from '@/ui/molecules/modal'

/* Cookie Consent Drawer  */

export const CookieConsentContent = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xLarge};
  width: 100%;

  > p {
    max-width: 85rem;
  }

  ${MEDIA_QUERIES(theme).medium} {
    flex-direction: row;
  }
`
)

/** We have to set the height of the buttons to fit-content in order to counteract the
 * useragent stylesheet */
export const CookieConsentButtons = styled.div(
  ({ theme }) => `
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  gap: ${theme.spacing.default};
  justify-content: center;

  > button {
    height: fit-content;
    white-space: nowrap;
    width: 100%;
  }

  ${MEDIA_QUERIES(theme).medium} {
    align-self: flex-end;
    flex-direction: row;
    margin-left: auto;

    > button {
      width: 12.3rem;
    }
  }
`
)

/* Cookie Consent Modal  */

export const StyledModal = styled(Modal)(
  ({ theme }) => `
    height: 100%;
    margin: 0;
    width: 100%;

    ${MEDIA_QUERIES(theme).medium} {
      height: fit-content;
      margin: 10vh auto;
      width: 50vw;

      ${CookieConsentButtons} {
        > button {
          width: 50%;
        }
      }
    }
  `
)

export const CookieOptionWrapper = styled(Flex)`
  width: 100%;
`

export const AlwaysActiveLabel = styled(BodyText)`
  margin-left: auto;
  white-space: nowrap;
`
export const StyledToggle = styled(Toggle)`
  margin-left: auto;
  width: fit-content;
`

/* Cookie Banner  */

export const CookieAcknowledgementWrapper = styled.div<{ isOpen: boolean }>(
  ({ isOpen }) => `
  display: ${isOpen ? 'block' : 'none'}
  `
)

export const CookieBannerContent = styled.div(
  ({ theme }) => `
    align-items: center;
    background-color: ${theme.colors.white};
    bottom: 0;
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.default};
    position: fixed;

  ${MEDIA_QUERIES(theme).medium} {
      flex-direction: row;
      padding: ${theme.spacing.husky} 8rem;
  }
`
)

export const CookieBannerDescription = styled(BodyText)(
  ({ theme }) => `
  padding: ${theme.spacing.default} 0;
`
)

/* This is the purple button labeled "Close" */
export const CookieBannerCloseButton = styled(Button)(
  ({ theme }) => `
    width: 100%;
  ${MEDIA_QUERIES(theme).medium} {
      margin-left: 6.4rem;
      max-width: 20rem;
  }
`
)

export const CookieBannerBackgroundOverlay = styled.div(
  ({ theme }) => `
    background-color: ${theme.colors.grey.dark1};
    height: 100%;
    opacity: 0.5;
    position: fixed;
    top: 0px;
    width: 100%;
  `
)
