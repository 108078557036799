import { type CookieRegistry } from '@/constants/cookieRegistry'

import { getCookieSubDomain } from './getCookieSubDomain'

export const createCookie = (
  key: CookieRegistry,
  value: string,
  {
    expiryInDays,
    useSubDomainSafe = true,
    fullDomainOnProd = false,
  }: { expiryInDays?: number; useSubDomainSafe?: boolean; fullDomainOnProd?: boolean } = {}
) => {
  let domain = ''
  const hostname = global.location.hostname
  const isProdAndShouldUseFullDomain = hostname.includes('kijiji.ca') && fullDomainOnProd

  if (useSubDomainSafe && !isProdAndShouldUseFullDomain) {
    const subDomainCookie = getCookieSubDomain(hostname)
    domain = `${' '}domain=${subDomainCookie};`
  }

  if (expiryInDays) {
    const date = new Date()
    date.setTime(date.getTime() + expiryInDays * 24 * 60 * 60 * 1000)
    const expires = `expires=${date.toUTCString()};`
    document.cookie = `${key}=${value}; ${expires}${domain} path=/`
  } else {
    document.cookie = `${key}=${value};${domain} path=/`
  }
}
