import { LocalStorageRegistry } from '@/constants/localStorageRegistry'

/**
 * Sets the local storage to define if the user manually modified their location radius
 */
export const setModifiedRadiusLocalStorage = (value?: boolean) => {
  const updatedValue = typeof value === 'undefined' ? 'true' : value.toString()

  window.localStorage.setItem(LocalStorageRegistry.USER_ADJUSTED_RADIUS, updatedValue)
}
