import { CATEGORIES } from '@kijiji/category'
import { type StaticImageData } from 'next/image'

import { type SearchCategory } from '@/generated/graphql-types'
import categoryATVsImg from '~/public/next-assets/images/categories/atv.webp'
import categoryAutosPartsImg from '~/public/next-assets/images/categories/auto-parts.webp'
import categoryBoatsImg from '~/public/next-assets/images/categories/boats.webp'
import categoryCarsImg from '~/public/next-assets/images/categories/cars.webp'
import categoryClassicCarsImg from '~/public/next-assets/images/categories/classic-cars.webp'
import categoryCondosImg from '~/public/next-assets/images/categories/condos.webp'
import categoryFurnitureImg from '~/public/next-assets/images/categories/furniture.webp'
import categoryHeavyEquipmentImg from '~/public/next-assets/images/categories/heavy-equipment.webp'
import categoryHomeOutdoorImg from '~/public/next-assets/images/categories/home-outdoor.webp'
import categoryHousesSaleImg from '~/public/next-assets/images/categories/houses-sale.webp'
import categoryMotorcyclesImg from '~/public/next-assets/images/categories/motorcycles.webp'
import categoryRVsImg from '~/public/next-assets/images/categories/rvs.webp'
import categoryToolsImg from '~/public/next-assets/images/categories/tools.webp'
import feedArtCollectiblesImg from '~/public/next-assets/images/homepage/feed-popular/feed-popular-12.jpg'
import feedBabiesItemsImg from '~/public/next-assets/images/homepage/feed-popular/feed-popular-17.jpg'
import feedBathingChangingImg from '~/public/next-assets/images/homepage/feed-popular/feed-popular-19.jpg'
import feedRealEstateEnImg from '~/public/next-assets/images/homepage/feed-popular/feed-popular-34.jpg'
import feedRealEstateFrImg from '~/public/next-assets/images/homepage/feed-popular/feed-popular-34fr.jpg'
import feedJobsImg from '~/public/next-assets/images/homepage/feed-popular/feed-popular-45.jpg'
import feedHomeAppliancesImg from '~/public/next-assets/images/homepage/feed-popular/feed-popular-107.jpg'
import feedToyGamesImg from '~/public/next-assets/images/homepage/feed-popular/feed-popular-108.jpg'
import feedToolsImg from '~/public/next-assets/images/homepage/feed-popular/feed-popular-110.jpg'
import feedSportingGoodsExerciseImg from '~/public/next-assets/images/homepage/feed-popular/feed-popular-111.jpg'
import feedPhonesImg from '~/public/next-assets/images/homepage/feed-popular/feed-popular-132.jpg'
import feedFurnitureImg from '~/public/next-assets/images/homepage/feed-popular/feed-popular-235.jpg'
import feedBabyItemsImg from '~/public/next-assets/images/homepage/feed-popular/feed-popular-253.jpg'
import feedClothingImg from '~/public/next-assets/images/homepage/feed-popular/feed-popular-274.jpg'
import feedHomeIndoorImg from '~/public/next-assets/images/homepage/feed-popular/feed-popular-717.jpg'
import feedHomeRenovationMaterialsImg from '~/public/next-assets/images/homepage/feed-popular/feed-popular-727.jpg'
import feedIPodsMp3HeadphonesImg from '~/public/next-assets/images/homepage/feed-popular/feed-popular-767.jpg'
import feedServersImg from '~/public/next-assets/images/homepage/feed-popular/feed-popular-774.jpg'

export const ALL_CATEGORIES_ID = '0'
export const ALL_CATEGORIES_ID_NUM = 0
export const ALL_CATEGORIES_NAME_EN = 'All Categories'
export const ALL_CATEGORIES_NAME_FR = 'Les catégories'

export const DEFAULT_CATEGORY: SearchCategory = {
  id: ALL_CATEGORIES_ID_NUM,
  isDomCat: false,
  localizedName: ALL_CATEGORIES_NAME_EN,
  name: { en_CA: ALL_CATEGORIES_NAME_EN, fr_CA: ALL_CATEGORIES_NAME_FR },
  parentId: null,
  path: [],
  seoUrl: null,
}

export const CATEGORY_ASSETS: { [id: string]: StaticImageData } = {
  [CATEGORIES.APARTMENTS_CONDOS_CATEGORY_ID]: categoryCondosImg,
  [CATEGORIES.ATVS_SNOWMOBILES_CATEGORY_ID]: categoryATVsImg,
  [CATEGORIES.AUTO_PARTS_TIRES_CATEGORY_ID]: categoryAutosPartsImg,
  [CATEGORIES.BOATS_WATERCRAFT_CATEGORY_ID]: categoryBoatsImg,
  [CATEGORIES.CARS_AND_TRUCKS_CATEGORY_ID]: categoryCarsImg,
  [CATEGORIES.CLASSIC_CARS_CATEGORY_ID]: categoryClassicCarsImg,
  [CATEGORIES.FURNITURE_CATEGORY_ID]: categoryFurnitureImg,
  [CATEGORIES.HEAVY_EQUIPMENT_CATEGORY_ID]: categoryHeavyEquipmentImg,
  [CATEGORIES.HOME_OUTDOOR_CATEGORY_ID]: categoryHomeOutdoorImg,
  [CATEGORIES.HOUSES_FOR_SALE_CATEGORY_ID]: categoryHousesSaleImg,
  [CATEGORIES.MOTORCYCLES_CATEGORY_ID]: categoryMotorcyclesImg,
  [CATEGORIES.RVS_CAMPERS_TRAILERS_CATEGORY_ID]: categoryRVsImg,
  [CATEGORIES.TOOLS_CATEGORY_ID]: categoryToolsImg,
}

export const FEED_CATEGORY_ASSETS: { [id: string]: StaticImageData } = {
  [CATEGORIES.ART_COLLECTIBLES_CATEGORY_ID]: feedArtCollectiblesImg,
  [CATEGORIES.BABY_ITEMS_CATEGORY_ID]: feedBabiesItemsImg,
  [CATEGORIES.BABY_ITEMS_CATEGORY_ID]: feedBabyItemsImg,
  [CATEGORIES.BATHING_CHANGING_CATEGORY_ID]: feedBathingChangingImg,
  [CATEGORIES.CLOTHING_CATEGORY_ID]: feedClothingImg,
  [CATEGORIES.FURNITURE_CATEGORY_ID]: feedFurnitureImg,
  [CATEGORIES.HOME_APPLIANCES_CATEGORY_ID]: feedHomeAppliancesImg,
  [CATEGORIES.HOME_INDOOR_CATEGORY_ID]: feedHomeIndoorImg,
  [CATEGORIES.HOME_RENOVATION_MATERIALS_CATEGORY_ID]: feedHomeRenovationMaterialsImg,
  [CATEGORIES.IPODS_MP3_HEADPHONES_CATEGORY_ID]: feedIPodsMp3HeadphonesImg,
  [CATEGORIES.JOBS_CATEGORY_ID]: feedJobsImg,
  [CATEGORIES.PHONES_CATEGORY_ID]: feedPhonesImg,
  [CATEGORIES.SERVERS_CATEGORY_ID]: feedServersImg,
  [CATEGORIES.SPORTING_GOODS_EXERCISE_CATEGORY_ID]: feedSportingGoodsExerciseImg,
  [CATEGORIES.TOOLS_CATEGORY_ID]: feedToolsImg,
  [CATEGORIES.TOYS_GAMES_CATEGORY_ID]: feedToyGamesImg,
  [CATEGORIES.REAL_ESTATE_CATEGORY_ID]: feedRealEstateEnImg,
  [`${CATEGORIES.REAL_ESTATE_CATEGORY_ID}fr`]: feedRealEstateFrImg,
}
