import { useMemo } from 'react'

import { type GetLocaleReturn, getLocale, LANGUAGE_KEY } from '@/domain/locale'
import { useGetUserLocaleQuery } from '@/generated/graphql-types'
import { updateUserLocaleCache } from '@/utils/locale/updateUserLocale'

export const ROUTE_LOCALE = {
  en: 'en',
  fr: 'fr',
} as const

export type RouteLocale = 'en' | 'fr'

export type UseLocaleReturn = GetLocaleReturn & {
  localeSwap: GetLocaleReturn
  updateLocale: (routeLocale: string) => void
}

/**
 * Due to the need to remove `/fr` from our routes we need a way to quickly swap between
 * using the locale from the router and using the locale from the cookie.
 */
export const useLocale = (): UseLocaleReturn => {
  const { data, client } = useGetUserLocaleQuery()
  const locale: GetLocaleReturn = getLocale(data?.userLocale.routeLocale)

  const updateLocale = (routeLocale: string) => {
    updateUserLocaleCache(routeLocale, client)
  }

  const localeValues = useMemo(() => {
    const localeSwap = getLocale(
      locale.languageKey === LANGUAGE_KEY.en ? LANGUAGE_KEY.fr : LANGUAGE_KEY.en
    )

    return {
      ...locale,
      localeSwap,
    }
  }, [locale])

  return { ...localeValues, updateLocale }
}
