import { OptimizelyClient } from '@/lib/optimizely/OptimizelyClient'

import { type GaEvent } from '../constants/gaEvent'
import { pushToDataLayer } from './dataLayer'

export type CoreEventArgs = {
  action: GaEvent
  label?: string
}

export type TrackEventArgs = CoreEventArgs & {
  name?: string
  nonInteraction?: number
  sendToOptimizely?: boolean
  customParameters?: Record<string, unknown>
}

export const trackEvent = ({
  action,
  customParameters,
  label = '',
  name,
  nonInteraction = 0,
  sendToOptimizely = false,
}: TrackEventArgs) => {
  pushToDataLayer({
    event: action,
    event_name: name || action,
    eventLabel: label,
    uaNonInt: nonInteraction,
    ...customParameters,
  })

  /** Sends event to optimizely dashboard if the passed flag is true */
  !!sendToOptimizely && OptimizelyClient.trackEvent(action)
}

export const trackPageView = (url: string) => {
  pushToDataLayer(
    {
      event: 'VirtualPageView',
      virtualPageUrl: url,
    },
    0,
    true
  )
}
