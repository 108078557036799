import { type ApolloClient, type NormalizedCacheObject } from '@apollo/client'

import { getLocale } from '@/domain/locale'
import { GetUserLocaleDocument } from '@/generated/graphql-types'

export const updateUserLocaleCache = (
  locale: string,
  client: ApolloClient<NormalizedCacheObject>
) => {
  const locales = getLocale(locale)

  client.cache.writeQuery({
    query: GetUserLocaleDocument,
    data: {
      userLocale: {
        apiLocale: locales.apiLocale,
        cookieLocale: locales.cookieLocale,
        languageKey: locales.languageKey,
        routeLocale: locales.routeLocale,
      },
    },
  })
}
