import { CookieRegistry } from '@/constants/cookieRegistry'
import { type Locale } from '@/generated'
import { createCookie } from '@/utils/cookies/createCookie'
import { deleteCookie } from '@/utils/cookies/deleteCookie'
import { getCookieByKey } from '@/utils/cookies/getCookieByKey'
import { isServer } from '@/utils/isSSR'

export const API_LOCALE = {
  en_CA: 'en_CA',
  fr_CA: 'fr_CA',
} as const

export const COOKIE_LOCALE = {
  en_CA: 'en-CA',
  fr_CA: 'fr-CA',
} as const

export const LANGUAGE_KEY = {
  en: 'en',
  fr: 'fr',
} as const

export const ROUTE_LOCALE = {
  en: 'en',
  fr: 'fr',
} as const

export type ApiLocale = keyof Omit<Locale, '__typename'>
export type CookieLocale = 'en-CA' | 'fr-CA'
export type LanguageKey = 'en' | 'fr'
export type RouteLocale = 'en' | 'fr' // TODO: Why are there two types for this?

export type GetLocaleReturn = {
  apiLocale: ApiLocale
  cookieLocale: CookieLocale
  languageKey: LanguageKey
  routeLocale: RouteLocale
}

/**
 * Used when you don't have access to the hook (useLocale). Gets / transforms the locale
 * into all of the possible use-cases.
 *
 * If you need to get the corresponding values based off a lang (language) key, pass that in
 * to get the respective transformed values
 *   lang = 'en'
 *     => apiLocale = 'en_CA'
 *     => cookieLocale = 'en-CA'
 *     => languageKey = 'en'
 *     => routeLocale = 'en'
 *
 * - en_CA | fr_CA => API Layer
 * - en-CA | fr-CA => Cookie Layer
 * - en | fr => Routes
 */
export const getLocale = (
  lang?: ApiLocale | CookieLocale | LanguageKey | RouteLocale | string
): GetLocaleReturn => {
  return lang ? getLocaleFromLang(lang) : getLocaleFromCookie()
}

const getLocaleFromLang = (
  lang: ApiLocale | CookieLocale | LanguageKey | RouteLocale | string
): GetLocaleReturn => {
  const parsedLang = lang.substring(0, 2).toLocaleLowerCase()

  if (parsedLang === LANGUAGE_KEY.fr) {
    return {
      apiLocale: API_LOCALE.fr_CA,
      cookieLocale: COOKIE_LOCALE.fr_CA,
      languageKey: LANGUAGE_KEY.fr,
      routeLocale: ROUTE_LOCALE.fr,
    }
  }

  return {
    apiLocale: API_LOCALE.en_CA,
    cookieLocale: COOKIE_LOCALE.en_CA,
    languageKey: LANGUAGE_KEY.en,
    routeLocale: ROUTE_LOCALE.en,
  }
}

const getLocaleFromCookie = (): GetLocaleReturn => {
  let cookieLocale: string
  if (isServer() || !document) {
    cookieLocale = COOKIE_LOCALE.en_CA
  } else {
    cookieLocale = getCookieByKey(document.cookie, CookieRegistry.SITE_LOCALE)
  }

  if (cookieLocale === COOKIE_LOCALE.fr_CA) {
    return {
      apiLocale: API_LOCALE.fr_CA,
      cookieLocale: COOKIE_LOCALE.fr_CA,
      languageKey: LANGUAGE_KEY.fr,
      routeLocale: ROUTE_LOCALE.fr,
    }
  }

  return {
    apiLocale: API_LOCALE.en_CA,
    cookieLocale: COOKIE_LOCALE.en_CA,
    languageKey: LANGUAGE_KEY.en,
    routeLocale: ROUTE_LOCALE.en,
  }
}

/** Set locale cookie on any location change for parity with legacy
 * which uses the subdomain .kijiji.ca */
export const setLocaleCookie = (cookieLocale: CookieLocale) => {
  /**
   * remove siteLocale cookies with full domain (www.kijiji.ca)
   * empty string defaults to current page domain
   */
  deleteCookie(CookieRegistry.SITE_LOCALE, '')

  createCookie(CookieRegistry.SITE_LOCALE, cookieLocale, {
    fullDomainOnProd: false,
  })
}

/**
 * Parse a string as a RouteLocale if valid.
 *
 * @param lang incoming language key
 * @returns input typed as RouteLocale if the lang is a valid route locale, otherwise undefined
 */
export function parseAsRouteLocale(lang: string): RouteLocale | undefined {
  switch (lang) {
    case ROUTE_LOCALE.en:
      return ROUTE_LOCALE.en
    case ROUTE_LOCALE.fr:
      return ROUTE_LOCALE.fr
    default:
      return undefined
  }
}
