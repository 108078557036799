import {
  AlgoliaSearchAttempt,
  AlgoliaSearchFail,
  AlgoliaSearchSuccess,
  GetAlgoliaKeyAttempt,
  GetAlgoliaKeyFail,
} from '../events/algolia'
import { AppDirBegin, AppDirDisplay } from '../events/appPen'
import {
  IcoBegin,
  IcoBeginKilo,
  IcoBeginProvince,
  IcoBeginVin,
  IcoBOS,
  IcoComplete,
  IcoFaq,
  IcoGetStartedAttempt,
  IcoGetStartedCancel,
  IcoGetStartedFail,
  IcoGetStartedSuccess,
  IcoGetYourOffer,
  IcoPostAdBegin,
  IcoVinHelp,
  PmrBeginKilo,
  PmrBeginProvince,
  PmrBeginVin,
  PmrBOS,
  PmrFaq,
  PmrGetICO,
  PmrGetValueAttempt,
  PmrGetValueCancel,
  PmrGetValueFailure,
  PmrGetValueSuccess,
  PmrPostAdBegin,
  PmrVinHelp,
  ValueEstimateBegin,
  ValueEstimateComplete,
} from '../events/autoCanada'
import * as CategoryEvents from '../events/category'
import {
  CookieAcceptAll,
  CookieAcknowledge,
  CookieManagePreferences,
  CookiePreferenceSave,
} from '../events/cookies'
import * as FilterEvents from '../events/filter'
import * as LocationEvents from '../events/location'
import { LoginBegin, LoginFail, LoginSuccess, LogoutSuccess } from '../events/login'
import {
  KijijiMyBusinessOpen,
  MyAccountOpen,
  MyAccountSettingsOpen,
  MyAdsOpen,
  MyOrdersOpen,
} from '../events/myKijiji'
import {
  PasswordResetAttempt,
  PasswordResetFail,
  PasswordResetSent,
  PasswordResetSuccess,
} from '../events/passwordReset'
import { PostAdBegin } from '../events/postAd'
import { PSAClick, PSADismissed, PSALoaded } from '../events/psa'
import {
  SaveSearchBegin,
  SaveSearchDeleteSuccess,
  SaveSearchFail,
  SaveSearchModalCancel,
  SaveSearchModalLoad,
  SaveSearchSuccess,
} from '../events/saveSearch'
import {
  CrossPromoBadgeClick,
  ErrorStateLoad,
  ResultsBrowse,
  ResultsSearch,
  SearchAttempt,
  SearchBegin,
  SelectPromotion,
  TopAdLearnMoreClick,
  ViewPromotion,
} from '../events/search'
import {
  UserActivationFail,
  UserActivationSuccess,
  UserRegistrationAttempt,
  UserRegistrationBegin,
  UserRegistrationFail,
  UserRegistrationSuccess,
} from '../events/userRegistration'
import { WatchlistAdd, WatchlistOpen, WatchlistRemove } from '../events/watchlist'

export type GaEvent = (typeof GA_EVENT)[keyof typeof GA_EVENT]

export const GA_EVENT = {
  ...CategoryEvents,
  ...FilterEvents,
  ...LocationEvents,
  [AlgoliaSearchAttempt]: AlgoliaSearchAttempt,
  [AlgoliaSearchFail]: AlgoliaSearchFail,
  [AlgoliaSearchSuccess]: AlgoliaSearchSuccess,
  [AppDirBegin]: AppDirBegin,
  [AppDirDisplay]: AppDirDisplay,
  [CookieAcceptAll]: CookieAcceptAll,
  [CookieAcknowledge]: CookieAcknowledge,
  [CookieManagePreferences]: CookieManagePreferences,
  [CookiePreferenceSave]: CookiePreferenceSave,
  [ErrorStateLoad]: ErrorStateLoad,
  [GetAlgoliaKeyAttempt]: GetAlgoliaKeyAttempt,
  [GetAlgoliaKeyFail]: GetAlgoliaKeyFail,
  [KijijiMyBusinessOpen]: KijijiMyBusinessOpen,
  [LoginBegin]: LoginBegin,
  [LoginFail]: LoginFail,
  [LoginSuccess]: LoginSuccess,
  [LogoutSuccess]: LogoutSuccess,
  [MyAccountOpen]: MyAccountOpen,
  [MyAccountSettingsOpen]: MyAccountSettingsOpen,
  [MyAdsOpen]: MyAdsOpen,
  [MyOrdersOpen]: MyOrdersOpen,
  [PasswordResetAttempt]: PasswordResetAttempt,
  [PasswordResetFail]: PasswordResetFail,
  [PasswordResetSent]: PasswordResetSent,
  [PasswordResetSuccess]: PasswordResetSuccess,
  [PostAdBegin]: PostAdBegin,
  [PSAClick]: PSAClick,
  [PSADismissed]: PSADismissed,
  [PSALoaded]: PSALoaded,
  [ResultsBrowse]: ResultsBrowse,
  [ResultsSearch]: ResultsSearch,
  [SaveSearchBegin]: SaveSearchBegin,
  [SaveSearchDeleteSuccess]: SaveSearchDeleteSuccess,
  [SaveSearchFail]: SaveSearchFail,
  [SaveSearchModalCancel]: SaveSearchModalCancel,
  [SaveSearchModalLoad]: SaveSearchModalLoad,
  [SaveSearchSuccess]: SaveSearchSuccess,
  [SearchAttempt]: SearchAttempt,
  [SearchBegin]: SearchBegin,
  [TopAdLearnMoreClick]: TopAdLearnMoreClick,
  [UserActivationFail]: UserActivationFail,
  [UserActivationSuccess]: UserActivationSuccess,
  [UserRegistrationAttempt]: UserRegistrationAttempt,
  [UserRegistrationBegin]: UserRegistrationBegin,
  [UserRegistrationFail]: UserRegistrationFail,
  [UserRegistrationSuccess]: UserRegistrationSuccess,
  [ValueEstimateBegin]: ValueEstimateBegin,
  [WatchlistAdd]: WatchlistAdd,
  [WatchlistOpen]: WatchlistOpen,
  [WatchlistRemove]: WatchlistRemove,
  BlockTitleClick: 'BlockTitleClick',
  BrandedCategorySelected: 'BrandedCategorySelected',
  ButtonClick: 'button_click',
  CasBackfillsShowMore: 'ShowMoreAdmarktClick',
  CasBackfillsShowMoreLoad: 'ShowMoreAdmarktLoad',
  CasBackfillsShowMoreView: 'ShowMoreAdmarktView',
  CategorySelected: 'CategorySelected',
  CrossPromoBadgeClick: CrossPromoBadgeClick,
  DownloadAppClick: 'DownloadAppClick',
  HelpDeskClick: 'HelpDeskClick',
  HPFLoadMore: 'HPFLoadMore',
  IcoBegin: IcoBegin,
  IcoBeginKilo: IcoBeginKilo,
  IcoBeginProvince: IcoBeginProvince,
  IcoBeginVin: IcoBeginVin,
  IcoBOS: IcoBOS,
  IcoComplete: IcoComplete,
  IcoFaq: IcoFaq,
  IcoGetStartedAttempt: IcoGetStartedAttempt,
  IcoGetStartedCancel: IcoGetStartedCancel,
  IcoGetStartedFail: IcoGetStartedFail,
  IcoGetStartedSuccess: IcoGetStartedSuccess,
  IcoGetYourOffer: IcoGetYourOffer,
  IcoPostAdBegin: IcoPostAdBegin,
  IcoVinHelp: IcoVinHelp,
  KijijiCentralClick: 'KijijiCentralClick',
  MessageCenterOpen: 'MessageCenterOpen',
  NotificationCentreOpen: 'NotificationCentreOpen',
  PmrBeginKilo: PmrBeginKilo,
  PmrBeginProvince: PmrBeginProvince,
  PmrBeginVin: PmrBeginVin,
  PmrBOS: PmrBOS,
  PmrFaq: PmrFaq,
  PmrGetICO: PmrGetICO,
  PmrGetValueAttempt: PmrGetValueAttempt,
  PmrGetValueCancel: PmrGetValueCancel,
  PmrGetValueFailure: PmrGetValueFailure,
  PmrGetValueSuccess: PmrGetValueSuccess,
  PmrPostAdBegin: PmrPostAdBegin,
  PmrVinHelp: PmrVinHelp,
  RecentAdClick: 'RecentAdClick',
  RecommendationAdClick: 'RecommendationAdClick',
  RecommendedAdClick: 'RecommendedAdClick',
  RecommendedForYouDisplayed: 'RecommendedDisplayed',
  RelatedKeywordClick: 'RelatedKeywordClick',
  ResultsImpressions: 'ResultsImpressions',
  SelectPromotion: SelectPromotion,
  SwapSelected: 'SwapSelected',
  SwitchLanguage: 'SwitchLanguage',
  ValueEstimateComplete: ValueEstimateComplete,
  ViewPromotion: ViewPromotion,
  WantedSelected: 'WantedSelected',
  PriceDropDismiss: 'pricedrop_dismiss',
} as const
