import { DEFAULT_USER_LOCATION } from '@/constants/location'
import { type UserLocation } from '@/generated/graphql-types'
import { getLatestSearchCookieValues } from '@/utils/cookies/latestSearchCookie'

import { convertCookieToUserLocation } from './convertCookieToUserLocation'

/**
 * Returns the initial location data from latest search cookie
 * If none found, returns the default location
 */
export const getInitialUserLocation = (userPreferenceCookie?: string): UserLocation => {
  const latestSearch = getLatestSearchCookieValues(userPreferenceCookie)

  /**
   * Set location as "allCanada" if no cookie was ever set
   */
  if (!latestSearch) {
    return DEFAULT_USER_LOCATION
  }

  const location = convertCookieToUserLocation(latestSearch)

  return location
}
