import {
  DialogContent,
  DialogContentProps,
  DialogOverlay,
  DialogOverlayProps,
} from '@reach/dialog'
import styled from 'styled-components'

export const StyledModalOverlay: React.FC<DialogOverlayProps> = styled(
  DialogOverlay
)`
  display: flex;
`

type StyledModalProps = DialogContentProps & {
  $isCentered?: boolean
  $scrollBehavior: 'inside' | 'outside'
}

/* TODO: Replace the z-index value with theme z-index scale values once it's created */
export const StyledModal: React.FC<StyledModalProps> = styled(
  DialogContent
)<StyledModalProps>(
  ({ theme, $isCentered, $scrollBehavior }) => `
    background: ${theme.colors.white};
    border-radius: ${theme.borderRadius.small};
    box-shadow: ${theme.boxShadow['shadow-2']};
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.default} ${theme.spacing.large};
    position: relative;
    z-index: 300;

    ${$scrollBehavior === 'outside' ? 'height: fit-content;' : ''}
    ${$isCentered ? `margin: auto auto;` : ''}
  `
)

export const StyledModalHeader = styled.header`
  padding: ${({ theme }) => theme.spacing.default} 0;
`

export const StyledModalBody = styled.div`
  overflow: auto;
`

export const StyledModalFooter = styled.footer`
  padding-top: ${({ theme }) => theme.spacing.default};
`

export const StyledModalCloseButton = styled.button<{ isDarkModal?: boolean }>(
  ({ theme, isDarkModal }) => `
  align-items: center;
  background-color: ${isDarkModal ? 'transparent' : theme.colors.grey.light4};
  border-radius: ${theme.borderRadius.rounded};
  display: flex;
  height: ${theme.spacing.xLarge};
  justify-content: center;
  position: absolute;
  right: 1.1rem;
  top: 1.1rem;
  width: ${theme.spacing.xLarge};

  &:hover {
    background-color: ${isDarkModal ? 'transparent' : theme.colors.grey.light3};
  }
  
  & > svg {
    height: ${isDarkModal ? theme.spacing.giant : theme.spacing.large};
    width: ${isDarkModal ? theme.spacing.giant : theme.spacing.large};

    color:  ${isDarkModal ? theme.colors.white : 'current'};
  }
`
)
