import { LatestSearchCookieRegistry } from '@/constants/cookieRegistry'
import { type UserLocation } from '@/generated/graphql-types'
import { type LatestSearchCookie } from '@/utils/cookies/latestSearchCookie'

/**
 * Extracts and normalizes the location values from the latest search cookie
 *
 * @param latestSearch the latest search cookie values in object format
 * @returns
 */
export const convertLocationToLatestSearchCookie = (
  location: UserLocation
): LatestSearchCookie => ({
  [LatestSearchCookieRegistry.ADDRESS]: location.area?.address?.toString(),
  [LatestSearchCookieRegistry.LATITUDE]: location.area?.latitude?.toString(),
  [LatestSearchCookieRegistry.LOCATION_ID]: location.id.toString(),
  [LatestSearchCookieRegistry.LONGITUDE]: location.area?.longitude?.toString(),
  [LatestSearchCookieRegistry.RADIUS]: location.area?.radius?.toString(),
})
