import { setContext } from '@apollo/client/link/context'

import { type LanguageKey } from '@/domain/locale'
import { OptimizelyClient } from '@/lib/optimizely/OptimizelyClient'
import OptimizelyServerManager from '@/lib/optimizely/OptimizelyServerManager'
import { serializeCookies } from '@/utils/cookies/serializeCookies'
import { isServer } from '@/utils/isSSR'
import { sendToLogger } from '@/utils/sendToLogger'

import { type RequestHeaders } from '../apolloClient'

// Type guard to check if optimizelyInstance is OptimizelyServerManager
function isOptimizelyServerManager(
  instance: OptimizelyServerManager | OptimizelyClient
): instance is OptimizelyServerManager {
  return isServer()
}

const contextLink = (languageKey: LanguageKey, requestHeaders?: RequestHeaders) => {
  return setContext(async (_, previousContext) => {
    const { headers = {}, cookies } = requestHeaders || {}
    const cookieHeader = cookies ? { Cookie: serializeCookies(cookies) } : {}

    const newContext = {
      headers: {
        ...headers,
        ...(previousContext.headers || {}),
        'Accept-Language': languageKey,
        ...cookieHeader,
      },
    }

    // if the incoming request does not have experiments context added, return the new context without querying optimizely
    if (!previousContext.experiments) {
      return newContext
    }

    try {
      // If changing this condition, be sure to change the isOptimizelyServerManager typeguard above
      const optimizely = isServer() ? OptimizelyServerManager : OptimizelyClient
      const optimizelyClient = optimizely.getClient()

      if (!optimizelyClient) {
        // Log error if Optimizely client is not available
        sendToLogger(new Error('Optimizely client not available'), {
          tags: { component: 'contextLink' },
        })
        return newContext
      }

      await optimizelyClient.onReady()
      const optimizelyInstance = optimizely.getInstance()
      const flags = Array.isArray(previousContext.experiments)
        ? previousContext.experiments
        : undefined

      let experimentsHeaderValue

      if (isOptimizelyServerManager(optimizelyInstance)) {
        experimentsHeaderValue = optimizelyInstance.decideTestGroupWithUserContext(
          { id: requestHeaders?.cookies?.['machId'] },
          flags
        )
      } else {
        experimentsHeaderValue = await optimizelyInstance.getSessionTestGroups(flags)
      }

      const experimentHeader = experimentsHeaderValue
        ? { 'x-experiments': experimentsHeaderValue }
        : {}

      const { headers: newHeaders, ...restContext } = newContext

      return {
        ...restContext,
        headers: {
          ...newHeaders,
          ...experimentHeader,
        },
      }
    } catch (error) {
      sendToLogger(error, {
        tags: { fn: 'optimizelyclient.onReady', component: 'contextLink' },
      })
      return newContext
    }
  })
}

export default contextLink
