import { signIn, useSession } from 'next-auth/react'
import { useEffect } from 'react'

import { sanitizeLoginUrl } from '@/domain/urls'
import { isUserAuthenticated } from '@/domain/user'
import { useLegacySafePush } from '@/hooks/useLegacySafePush'

import { useLocale } from './useLocale'

type UseSigninProps = {
  callbackUrl?: string
  goToSignIn?: boolean
  tryRefresh?: boolean
  systemMessageParams?: { [name: string]: string }
}

export const useSignin = ({
  callbackUrl = '/',
  goToSignIn = true,
  tryRefresh = false,
  systemMessageParams = {},
}: UseSigninProps) => {
  const session = useSession()
  const { status } = session ?? { status: 'loading' }
  const { routeLocale } = useLocale()
  const push = useLegacySafePush()

  useEffect(() => {
    if (status === 'loading') return

    if (isUserAuthenticated(status) && goToSignIn && !tryRefresh) {
      push('/')
      return
    }

    if (goToSignIn) {
      const centralRedirectUrl = sanitizeLoginUrl(callbackUrl, systemMessageParams)

      signIn('cis', { callbackUrl: centralRedirectUrl || '/' }, { locale: routeLocale })
    }

    if (tryRefresh) {
      signIn('cis')
    }
  }, [callbackUrl, routeLocale, goToSignIn, tryRefresh, status, push, systemMessageParams])
}
