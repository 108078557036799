export enum PAGE_TYPE {
  AUTOS_LANDING_PAGE = 'landing_page',
  BACK_TO_SCHOOL_LANDING_PAGE = 'back-to-school',
  BROWSE_AD = 'ResultsBrowse',
  BROWSE_AD_GALLERY = 'BrowseAd-Gallery',
  CAS_LOGIN_PAGE = 'CasLogin',
  CAS_LOGIN_PAGE_FLOW = 'CasUserLoginForm',
  CAS_USER_REGISTRATION = 'CasUserRegistrationForm',
  CAS_USER_REGISTRATION_BILLING = 'CasUserBillingInfoForm',
  CAS_USER_REGISTRATION_INVALID_LOGIN = 'CasUserRegToLogin',
  CAS_USER_REGISTRATION_PROFILE = 'CasUserContactInfoForm',
  CAS_USER_REGISTRATION_THANKYOU = 'CasUserThanksPage',
  CATEGORY_LANDING_PAGE = 'LandingPageCategory',
  CHECKOUT_BILLING_FORM = 'CheckoutBilling',
  EDIT_AD_FORM = 'EditAd',
  ERROR_404 = 'Error_404',
  ERROR_500 = 'Error_500',
  FORGOT_PASSWORD = 'PasswordForgotten',
  HOMEPAGE = 'Homepage',
  HOME_SWEET_HOME_LANDING_PAGE = 'home-sweet-home',
  LANDING_PAGE = 'LandingPage',
  LANDING_PAGE_GENERAL = 'landing-page-general',
  LOGIN_PAGE = 'Login',
  LOGOUT_PAGE = 'Logout',
  MOBILE_APP_LINK = 'MobileAppLink',
  MRP = 'Mrp',
  MY_ADMARKT_PROFILE = 'MyAdmarktAccount',
  MY_ADS = 'MyAds',
  MY_MESSAGES = 'messagecentre',
  MY_ORDERS = 'MyOrders',
  MY_PROFILE = 'MyAccount',
  MY_WATCHLIST = 'MyWatchlist',
  NOTIFICATIONS = 'notificationsCentre',
  ORDER_PAYMENT_ERROR = 'OrderPaymentError',
  ORDER_PAYMENT_FORM = 'OrderPaymentForm',
  ORDER_SUCCESS = 'OrderSuccess',
  PASSWORD_RESET_FORM = 'PasswordResetForm',
  POSTERS_OTHER_ADS = 'ResultsSeller',
  POSTERS_OTHER_ADS_MAP = 'PostersOtherAdsMap',
  POST_AD_CONTACT = 'PostAdContact',
  POST_AD_FORM = 'PostAd',
  POST_AD_SELECT_CATEGORY = 'PostAdCategory',
  POST_AD_SUCCESS = 'PostAdSuccess',
  PREVIEW_AD = 'PreviewAd',
  PROMOTE_MY_ADS = 'PromoteMyAds',
  SEARCH_AD = 'ResultsSearch',
  SEARCH_AD_GALLERY = 'SearchAd-Gallery',
  SELLER_CAROUSEL = 'SellerCarousel',
  SELLER_VIEW_AD = 'SVIP',
  SIGNIN_ERROR = 'SigninErrorPage',
  SIGN_IN_ACTIVATE = 'SignInActivate',
  SITE_HOME = 'SiteHome',
  USER_REGISTRATION = 'UserRegistrationForm',
  USER_REGISTRATION_FAIL = 'UserRegistrationFail',
  USER_REGISTRATION_PAGE = 'UserRegistrationLandingPage',
  USER_REGISTRATION_SUCCESS = 'UserRegistrationSuccess',
  VIEW_AD = 'VIP',
  VIEW_EXPIRED_AD = 'eVIP',
  VIEW_LARGER_IMAGE = 'ViewLargerImage',
  VIEW_MAP = 'ViewMap',
  VIEW_PARTNER_AD = 'pVIP',
  ZERO_SEARCH_RESULTS_PAGE = 'ZSRP',
  SEARCH_RESULTS_PAGE = 'SRP',
}

export const PAGE_TYPE_GPT: { [key: string]: string } = {
  Homepage: 'homepage',
  landing_page: 'landing_page',
  Error_404: '404page',
  Error_500: 'errorpage',
  VIP: 'viewitem',
  ResultsSearch: 'srp',
  ResultsBrowse: 'srp',
  ViewLargerImage: 'viewimage',
  eVIP: 'evip',
  PostAdSuccess: 'postad',
  ViewMap: 'map',
  ZSRP: 'zsrp',
  MyAds: 'mykijiji',
  ResultsSeller: 'poa',
  'home-sweet-home': 'home-sweet-home',
  'back-to-school': 'back-to-school',
  'landing-page-general': 'landing-page-general',
  VIEW_A_CAS: 'viewitem-cas', // outlier
}
