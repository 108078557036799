import styled from 'styled-components'

import { styleFromTypography } from '@/ui/helpers/styleFromTypography'
import { RequiredPickMap } from '@/ui/typings/helpers'

import { HeadlineTextProps } from './HeadlineText'

type HeadlineTextBaseProps = RequiredPickMap<
  HeadlineTextProps,
  'weight' | 'color' | 'size' | 'textAlign'
>

export const HeadlineTextBase = styled.h1<HeadlineTextBaseProps>(
  ({ $weight, $color, $size, $textAlign, theme }) => `
  ${styleFromTypography(theme.typography.headline[$size])}
  color: ${$color};
  font-family: inherit;
  font-weight: ${theme.fontWeight[$weight]};
  text-align: ${$textAlign};
`
)
