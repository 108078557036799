import { type DocumentNode, InMemoryCache } from '@apollo/client'

import { DEFAULT_CATEGORY } from '@/constants/category'
import { getSrpUrlCacheKey } from '@/domain/srp/getSrpUrlCacheKey'
import {
  type SearchResultsByUrlInput,
  type SearchResultsPageState,
  GetAuthModalStateDocument,
  GetHomeFeedAdsDocument,
  GetSearchCategoryDocument,
  GetSearchKeywordDocument,
  GetSearchLoadingStateDocument,
} from '@/generated'

import { type RequestHeaders } from './apolloClient'
import { getInitialCookieCacheData } from './cacheFromCookies'
import { currentSrpUrl } from './currentSrpUrl'

export const getCacheFields = () => {
  const cache = {
    fields: {
      searchResultsPageByUrl: {
        keyArgs: (args: Record<'input', SearchResultsByUrlInput> | null) => {
          return (args && getSrpUrlCacheKey(args.input.url)) || undefined
        },
      },
      /**
       * The HomepageGallery & HomeFeed keyArgs are set to support the "fetchMore" pagination
       * The cache will only be wiped when those "keyArgs" change
       * */
      homepageGallery: { keyArgs: ['locationId'] },
      homeFeed: { keyArgs: ['locationId', 'cid', 'userId', 'location'] },
      menuPrefetch: { keyArgs: ['locationId', 'categoryId', 'locale'] },
      srp: {
        merge(
          existing: SearchResultsPageState,
          incoming: SearchResultsPageState,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          { mergeObjects }: { mergeObjects: any }
        ) {
          // TODO: Do i need this? what was i doing?
          return mergeObjects(existing, incoming)
        },
      },
    },
    currentSrpUrl: {
      read() {
        return currentSrpUrl()
      },
    },
  }

  return cache
}

export const localInitialState: Array<{
  query: DocumentNode
  data: Record<string, unknown>
}> = [
  {
    query: GetSearchKeywordDocument,
    data: { searchKeyword: { keyword: null } },
  },
  {
    query: GetSearchCategoryDocument,
    data: { searchCategory: DEFAULT_CATEGORY },
  },
  {
    query: GetSearchLoadingStateDocument,
    data: {
      srp: {
        loadingFilters: true,
        loadingResults: true,
      },
    },
  },
  {
    query: GetAuthModalStateDocument,
    data: {
      authModalState: {
        isModalOpen: false,
        modalVariant: null,
        callbackUrl: null,
      },
    },
  },
  {
    query: GetHomeFeedAdsDocument,
    data: {
      homeFeedAds: {
        adSlots: 0,
      },
    },
  },
]

export const writeInitialData = (cache: InMemoryCache, cookies?: RequestHeaders['cookies']) => {
  const initialCookieData = getInitialCookieCacheData(cookies)
  const initialStates = [...localInitialState, ...initialCookieData]

  initialStates.forEach((query) => cache.writeQuery(query))
}

export const initializeCache = (): InMemoryCache => {
  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: getCacheFields().fields,
      },
      ListingLocation: {
        keyFields: false,
      },
    },
  })

  return cache
}
