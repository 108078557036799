/* eslint-disable no-console*/
import { onError } from '@apollo/client/link/error'
import { type ScopeContext } from '@sentry/types'

import { logger } from '@/utils/sendToLogger'

const ignoredErrorQueries = ['geocodeReverseFromIp']

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      const error = `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
        locations
      )}, Path: ${path}`
      if (!ignoredErrorQueries.includes(path?.[0] as string)) {
        const context: Partial<ScopeContext> = {
          level: 'error',
          fingerprint: ['graphQLError'],
        }
        if (path && Array.isArray(path)) {
          context.fingerprint?.push(...path.map((pathSegment) => `${pathSegment}`))
        }
        if (extensions.statusCode) {
          context.fingerprint?.push(extensions.statusCode)
        }
        if (typeof window !== 'undefined') {
          context.extra = {
            pathname: window.location.pathname,
          }
        }
        logger.captureMessage(error, context)
        console.error(error)
      }
    })
  }

  if (networkError) {
    console.warn(`[Network error]: ${networkError}`)
  }
})

export default errorLink
