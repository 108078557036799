import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import styled from 'styled-components'

import { styleFromTypography } from '@/ui/helpers/styleFromTypography'

import { AccordionProps } from './Accordion'

type StyledAccordionProps = Pick<AccordionProps, 'gap'>

export const StyledAccordion = styled(Accordion)<StyledAccordionProps>(
  ({ gap }) => `
  display: flex;
  flex-direction: column;
  gap: ${gap}rem;
  width: 100%;
`
)

type StyledAccordionButtonProps = {
  $hasDescription?: boolean
}

export const StyledAccordionItem = styled(AccordionItem)(
  ({ theme }) => `
  background: ${theme.colors.white};

  & > div[role="button"] {
    cursor: pointer;
  }
 
  &[aria-disabled='true'] p {
    color: ${theme.colors.grey.light2};
  }

  &[aria-disabled='true'] > div[role="button"] {
    cursor: not-allowed;
  }
`
)

export const StyledAccordionButton = styled(
  AccordionItemButton
)<StyledAccordionButtonProps>(
  ({ theme, $hasDescription }) => `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${theme.spacing.default};
  padding-bottom: ${$hasDescription && theme.spacing.defaultSmall};
  width: 100%;
`
)

export const StyledAccordionPanel = styled(AccordionItemPanel)(
  ({ theme }) => `
  margin-top: ${theme.spacing.default};
  padding: 0 ${theme.spacing.default} ${theme.spacing.default} ${theme.spacing.default};
`
)

export const AccordionDescription = styled.div<{ $expanded: boolean }>(
  ({ theme, $expanded }) => `
  ${styleFromTypography(theme.typography.body.small)}
  color: ${theme.colors.grey.light1};
  text-align: left;

  ${$expanded ? 'display: none;' : ''}
`
)

export const RotatingChevronContainer = styled.div(
  ({ theme }) => `
  height: ${theme.spacing.default};
  width: ${theme.spacing.default};
`
)
