/// <reference types="@welldone-software/why-did-you-render" />
import React from 'react'

// Should work out of the box for pure components, for the rest, please add:
// ComponentName.whyDidYouRender = true

if (process.env.NODE_ENV === 'development') {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const whyDidYouRender = require('@welldone-software/why-did-you-render')
    whyDidYouRender(React, {
      //trackAllPureComponents: true,
      onlyLogs: true,
      titleColor: 'green',
    })
  }
}
