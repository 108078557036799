import { DialogProps } from '@reach/dialog'
import { FC, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import { StyledModal, StyledModalOverlay } from '@/ui/molecules/modal/styled'

export type ModalProps = DialogProps & {
  label: string
  isOpen: boolean
  onCancel: () => void
  portalRefId?: string
  isCentered?: boolean
  scrollBehavior?: 'inside' | 'outside'
}

/**
 * It renders within the web-portal in the root of the app
 */
export const Modal: FC<ModalProps> = ({
  label = 'Modal',
  children,
  isOpen,
  onCancel,
  portalRefId = '#portal',
  isCentered = false,
  scrollBehavior = 'outside',
  initialFocusRef,
  ...props
}) => {
  const ref = useRef<Element | null>(null)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    ref.current = document.querySelector<HTMLElement>(portalRefId)

    // If it can't find a portal element - create one and append to the body
    if (!ref.current) {
      const rootContainer = document.createElement('div')
      rootContainer.setAttribute('id', portalRefId)
      document.body.appendChild(rootContainer)

      ref.current = rootContainer
    }

    setMounted(true)
  }, [])

  return mounted && ref.current
    ? createPortal(
        <StyledModalOverlay
          data-testid="modal-overlay"
          isOpen={isOpen}
          onDismiss={onCancel}
          initialFocusRef={initialFocusRef}
        >
          <StyledModal
            aria-label={label}
            $isCentered={isCentered}
            $scrollBehavior={scrollBehavior}
            {...props}
          >
            {children}
          </StyledModal>
        </StyledModalOverlay>,
        ref.current
      )
    : null
}
