import { ReactNode } from 'react'

import { StyledAccordion } from './styled'

export type AccordionProps = {
  /**
   * Allow multiple accordion fields to be expanded at the same time
   * @default true
   */
  allowMultipleExpanded?: boolean
  /**
   * Allow none of the accordion items to be expanded at the same time
   * @default true
   */
  allowZeroExpanded?: boolean
  children: ReactNode
  /**
   * The gap between items in the accordion in rems
   * @default 2
   */
  gap?: number
  /**
   * List of accordion items uuid
   */
  preExpanded?: string[]
  /**
   * Callback which is invoked when items are expanded or collapsed.
   * Gets passed uuids of the currently expanded AccordionItems
   */
  onChange?: (uuid: string[]) => void
}

export const Accordion = ({
  gap = 2,
  children,
  preExpanded,
  onChange,
  ...props
}: AccordionProps) => {
  return (
    <StyledAccordion
      allowMultipleExpanded
      allowZeroExpanded
      data-testid="accordion-wrapper"
      gap={gap}
      preExpanded={preExpanded}
      onChange={onChange}
      {...props}
    >
      {children}
    </StyledAccordion>
  )
}
