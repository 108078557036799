import { LogLevel } from '@optimizely/js-sdk-logging'
import {
  type OptimizelyDecision,
  type ReactSDKClient,
  createInstance,
  setLogLevel,
} from '@optimizely/react-sdk'
import { isMobile } from 'react-device-detect'

import { type GaEvent } from '../ga/constants/gaEvent'
import { type FeatureFlag, FEATURE_FLAG, RELEVANCY_SORT_FEATURE_FLAGS } from '.'
import { EventBatchSize, EventFlushInterval } from './constants'

/**
 * Manages interactions with the Optimizely A/B testing service.
 * This is a singleton class to ensure one shared instance of the Optimizely client.
 * @example
 * const client = OptimizelyClient.getClient();
 */
export class OptimizelyClient {
  /** Shared instance of class */
  private static instance: null | OptimizelyClient = null
  /** Client instance */
  private client: ReactSDKClient
  /** Log level for the client */
  private logLevel: string | LogLevel = LogLevel.INFO

  private fullGATestGroupsString: string

  private constructor() {
    OptimizelyClient.instance = this
    this.setOptimizelyLogLevel()
    this.client = createInstance({
      sdkKey: process.env.NEXT_PUBLIC_OPTIMIZELY_SDK || '',
      eventBatchSize: EventBatchSize,
      eventFlushInterval: EventFlushInterval,
    })
    this.fullGATestGroupsString = ''
  }

  private setOptimizelyLogLevel(): void {
    if (process.env.NODE_ENV === 'production') {
      this.logLevel = LogLevel.ERROR
    } else if (process.env.NEXT_PUBLIC_OPTIMIZELY_LOG_MODE) {
      this.logLevel = process.env.NEXT_PUBLIC_OPTIMIZELY_LOG_MODE
    }

    setLogLevel(this.logLevel)
  }

  /**
   * Retrieves the shared OptimizelyClient instance.
   * Creates a new instance if it doesn't exist.
   * @example
   * const instance = OptimizelyClient.getInstance();
   */
  static getInstance = (): OptimizelyClient =>
    this.instance ? this.instance : new OptimizelyClient()

  /**
   * Retrieves the shared ReactSDK client.
   * @example
   * const client = OptimizelyClient.getClient();
   */
  static getClient = (): ReactSDKClient => this.getInstance().client

  /**
   * Disposes off the shared OptimizelyClient instance.
   * @example
   * OptimizelyClient.resetInstance();
   */
  static resetInstance = (): void => {
    this.instance = null
  }

  /**
   * Tracks an event with the given label
   * @param action Event label to be tracked
   * @example
   * OptimizelyClient.trackEvent("MyCoolEvent")
   */
  static trackEvent = (action: GaEvent): void => {
    this.getClient().track(action)
  }

  /*
   * For each decision:
   * 1. Make sure it is enabled
   * 2. Make sure it has a well defined GA_TagId
   * 3. Make sure it has a well defined GA_VariationId
   * 4. The above two have at least one character
   *
   * Otherwise, skip this decision
   */
  public static transformDecisionString(decisions: OptimizelyDecision[]) {
    const decisionString = decisions.reduce((acc, { flagKey, enabled, variables }) => {
      // TODO: REMOVE SECOND CONDITION AFTER EXPERIMENT IS COMPLETE
      if (!enabled && !RELEVANCY_SORT_FEATURE_FLAGS.includes(flagKey)) return acc
      /* Get the test group identifier and the variation */
      const dataLayerKey = typeof variables !== 'undefined' && variables['GA_TagId']
      const dataLayerValue = typeof variables !== 'undefined' && variables['GA_VariationId']

      /* Make sure the identifier and variation are valid */
      if (
        typeof dataLayerKey !== 'string' ||
        dataLayerKey.length === 0 ||
        typeof dataLayerValue !== 'string' ||
        dataLayerValue.length < 0
      )
        return acc

      /**
       * REMOVE AFTER HOMEPAGE ROLLOUT
       * This condition prevents the Homepage flag to be added in any page other than the Homepage
       */
      if (
        flagKey === FEATURE_FLAG.HOMEPAGE_ROLLOUT &&
        document.location.pathname !== '/' &&
        !document.location.pathname.startsWith('/h-')
      ) {
        return acc
      }

      return `${acc}${dataLayerKey}=${dataLayerValue};`
    }, '')

    return decisionString.endsWith(';') ? decisionString.slice(0, -1) : decisionString
  }

  /**
   * Decides all or whichever optimizely feature flags specified and converts them to a string
   *
   * @example
   * OptimizelyClient.getSessionTestGroups() // returns "KCAN1234=0;KCAN5678=1"
   */
  public getSessionTestGroups = (
    flags?: Array<FeatureFlag>,
    categoryId?: number
  ): Promise<string> => {
    /**
     * TODO: Refactor how we get decision array so that we don't need customized logic
     * for different features on the SRP
     */
    return this.client.onReady().then(({ success }) => {
      if (!success) {
        return ''
      }

      let decisions
      if (flags) {
        decisions = Object.values(this.client.decideForKeys(flags))
      } else if (categoryId) {
        decisions = Object.values(
          this.client.decideAll(undefined, undefined, {
            categoryId,
            isMobile,
          })
        )
      } else {
        decisions = Object.values(this.client.decideAll())
      }

      return OptimizelyClient.transformDecisionString(decisions)
    })
  }

  /**
   * Decides all optimizely feature flags and converts them to a string to be sent to GA
   * The assumption when using the function is that the optimizely client is ready, which
   * will be true if this function used after the client is initialized in _app.tsx
   *
   * @example
   * OptimizelyClient.getSessionTestGroups() // returns "KCAN1234=0;KCAN5678=1"
   */
  static getGaSessionTestGroups = async (categoryId?: number): Promise<string> => {
    const instance = OptimizelyClient.getInstance()
    instance.fullGATestGroupsString = await instance.getSessionTestGroups(undefined, categoryId)
    return instance.fullGATestGroupsString
  }
}
